const app = {
    modal: {
        close: 'Close',
        ok: 'OK',
        cancel: 'Cancel',
    },
    products: {
        title: 'Products',
        new: 'New',
        all: 'All Products',
        sizes: '',
        excludeVAT: 'excl. VAT',
        startDesign: 'Start Design',
        searchResults: 'Search results for &quot;<strong>{query}</strong>&quot;',
        noResults: 'No products found',
        showInShop: 'Show the product in your store',
        connectProduct: 'Connect TiB Product',
        connectProductId: 'Shopify Product ID',
    },
    tibProductConnect: {
        error: {
            title: 'Product could not be Connected',
            variantNotFound: 'The following variants could not be matched:\n',
        },
        success: {
            title: 'Product Successfully Connected',
        },
    },
    mainShop: {
        title: 'My shop',
    },
    orders: {
        title: 'Orders',
        personalized: 'Personalized orders',
        notEu: 'Non-EU orders',
        personalizedShort: 'Personalized',
        notEUShort: 'Not from the EU',
        all: 'All',
        splittedOrders: 'Part of this order:',
    },
    myDesignLibrary: {
        title: 'My Designs',
        deleteConfirm: 'Are you sure you want to delete this design?',
    },
    resources: {
        title: 'Resources',
        blog: 'Print-On-Demand Blog',
        whatsappChannel: 'WhatsApp Channel',
        productAndDeliverInformation: 'Product and delivery information',
        productImages: 'Product Images',
        helpAndFaqs: 'Help & FAQs',
    },
    deliveryInformation: 'Delivery information',
    account: {
        affiliateProgram: 'Affiliate Program',
        title: 'Account',
        invoices: 'Invoices',
        settings: 'Settings',
        logout: 'Logout',
        logoutMessage: 'You have been logged out due to problems with your account.',
    },
    pagination: {
        prev: 'Back',
        next: 'Next',
        further: 'Next',
        create: 'Create',
    },
    search: {
        placeholder: 'Search',
    },
    sort: {
        value: 'Sort',
    },
    filter: {
        label: 'Filter',
    },
    creditCard: {
        error: {
            title: 'Credit card could not be deposited',
            tokenContent: 'Invalid credit card Information',
            backendContent: 'Error when storing the data',
        },
        success: {
            title: 'Successfully saved',
            content: 'Your credit card has been successfully deposited',
        },
    },
    welcomeBlock: {
        title: `Welcome{username}`,
        suggest: 'Browse our product portfolio and create your unique print on demand product in less than 2 minutes.',
        subtitle:
            "--- You will find up-to-date known issues <a target='_blank' rel='noopener noreferrer' href='https://help.marketprint.de/known-issues/'>here</a>. ---<br><br>The long-awaited <b>MarketPrint 2.0</b> The update is finally online! We have changed the entire frontend to offer you an even better user experience.<br><br>If there are still minor problems within the first two weeks, please call us on the phone number<b>+49 8261 7939101</b>.<br>Or leave your opinion and suggestions for future innovations at: <b>feedback@marketconsultive.de</b><br><br>We wish you a lot of fun with the new design and are looking forward to hearing from you! We are far from over and thank you for your loyalty.<br><br>- The complete team of <i>MarketConsultive GmbH</i><br><br>PS: A few exciting features, such as your own design library, will be made available to you in the next few weeks! Take a look at <a target='_blank' rel='noopener noreferrer' href='https://www.marketprint.de'>www.marketprint.de</a> over! In addition, we are planning a complete product portfolio of at least 20 (!) new best-selling products that will go online within the next weeks and months.",
        cta: 'Create a product now',
    },
    productsIntro: {
        title: 'Our product range',
    },
    currentOrders: {
        title: `Current orders:`,
        more: 'Show more...',
    },
    currentOrdersModal: {
        shopifyId: 'Shopify ID',
        trackingNumber: 'Shipment number(s)',
        moreInfo: 'More info',
        paymentMethod: 'Payment method',
        transactionNumber: 'Transaction number',
        ordered: 'Appointed',
        inProcess: 'In production',
        shipped: 'Shipped',
        cancelled: 'Cancelled',
        refunded: 'Refunded',
        yes: 'Yes',
        no: 'No',
        btnUploadDesigns: 'Upload Designs',
        btnReleaseForProduction: 'Approve for production',
        btnViewOrder: 'Show Order',
        btnUpdateCustomerData: 'Update Address',
        updateCustomerData: 'Update Customer Data',
        btnDownloadInvoice: 'Download Invoice',
        textReleaseForProduction: 'Do you really want to approve this order for production?',
        orderReady: 'Order has been approved for production!',
        orderReadyErrorTitle: 'Order could not be approved for production!',
        orderReadyError: 'Check to see if all designs are complete!',
        btnUploadInvoices: 'Upload Invoice',
        invoice: 'Invoice',
        selectVariant: 'Choose a variant',
        selectImage: 'Personalized design',
        confirmationUpload: 'Upload successful!',
        notPayed: 'Payment to MarketPrint failed. Please try again in the settings or contact our support.',
        noPreview: 'Currently no preview for mugs! \n You can still upload the design.',
        for: 'for',
        btnRetryPayment: 'Retry payment',
        uploaded: 'Uploaded designs',
        downloadInvoice: 'Invoice Download',
        noInvoice: 'Invoice not available',
        noMockups: 'No mockups available for this product',
        btnCancel: 'Cancel order',
        cancelReason: 'Cancel reason',
        textCancel: 'Are you sure you want to cancel this order?',
        orderCancelErrorTitle: 'Order could not be cancelled',
        orderCancelled: 'Order has been cancelled',
        orderCancelError: 'The order might already be in production or shipped.',
    },
    checklist: {
        title: `Your checklist`,
        subtitle: `Only a few steps left to set up your shop!`,
        installApp: `Install the MarketPrint app`,
        createProduct: `Creatie a product`,
        paymentMethod: `Select a payment method`,
    },
    todoOrders: {
        title: `Your TODO's`,
        subtitle: `Complete your orders`,
        order: `Load the invoice for the order <strong>#{id}</strong> high`,
    },
    sortOptions: {
        relevance: 'Relevance',
        newArrivals: 'Newcomer',
        bestSelling: 'Best-selling',
        priceLowHigh: 'Price Low High',
        priceHighLow: 'Price High Low',
        ratings: 'Review',
    },
    home: {
        bestseller: 'Current MarketPrint Bestsellers',
        newProducts: 'New Products',
        shopConnectSuccess: {
            title: 'Shop connected',
            content: 'Your shop has been successfully connected to MarketPrint.',
        },
        shopConnectError: {
            title: 'Shop connection error',
            content: 'An error occurred while connecting your shop to MarketPrint. Please try again later.',
            alreadyConnected: 'An error occurred while connecting your shop to MarketPrint: The shop is already connected to another account.',
        },
    },
    ordersIntro: {
        orders: 'Order overview',
        personalized: 'Personalized orders',
        notEu: 'Non-EU orders',
    },
    design: {
        selectShop: 'For which Shop do you want to create a product?',
        selectShopTitle: 'Select Shop',
        front: 'Front',
        back: 'Back',
        left: 'Left',
        right: 'Right',
        colors: 'Colours',
        size: 'Size',
        sizes: 'Varying sizes',
        design: 'Design',
        countries: 'Countries',
        germany: 'Germany',
        austria: 'Austria',
        switzerland: 'Switzerland',
        landArea: 'Country / Area',
        alternativeDesign: 'Alternative Design',
        alternativeDesignColors: 'Alternative design for the following colors',
        productDescription: 'Product description',
        downloadProductData: 'Download product data sheet and mockups',
        designNote: 'Design creation notes',
        sizeChart: 'Size chart',
        insertGraphic: 'Insert graphic',
        important: `<span>Important:</span> Place your design inside the green outer marker. When crossing the lines, your design will not be printed completely on the product.`,
        productTitle: 'Product Titles',
        productTitlePlaceholder: 'Add a title',
        productDescriptionPlaceholder: 'Add description',
        doubleSidedPrint: 'Multi-sided printing - already included',
        purchasePriceVat: 'Purchase price (excl. VAT)',
        purchasePrice19Vat: 'Purchase price (incl. 19% VAT)',
        sellingPrice19VAt: 'Sales price (incl. 19% VAT)',
        profitMargin: 'Your profit margin (gross)',
        price: 'Price',
        shippingCostsOverview: 'Overview of shipping costs',
        shippingPrice: 'Shipping price',
        additionalPricePerProduct: 'An additional 1.00 € will be charged per additional product in the order',
        addSizeChart: 'Add a size chart to the shop',
        publishProduct: 'Publish product in Shopify',
        chooseMockup: 'Choose your mockup',
        titleLibrary: 'Your design library',
        addDesignLibrary: `Upload a new design`,
        personalProduct: 'This is a customizable product',
        tibProductFront: 'Optimize Front',
        tibProductBack: 'Optimize Back',
        tibProductSleeveLeft: 'Optimize Sleeve Left',
        tibProductSleeveRight: 'Optimize Sleeve Right',
        tibProductLeft: 'Optimize Left',
        tibProductRight: 'Optimize Right',
        tibProductFrontLeft: 'Optimize Front Left',
        tibProductFrontRight: 'Optimize Front Right',
        tibProductBackLeft: 'Optimize Back Left',
        tibProductBackRight: 'Optimize Back Right',
        tibProductSideLeft: 'Optimize Side Left',
        tibProductSideRight: 'Optimize Side Right',
        tibProductAll: 'Optimize All',
        quality: {
            good: 'Good',
            normal: 'Means',
            bad: 'Bad',
        },
        productCreated: 'Created a product',
        productCreateError: 'Product could not be created',
        maxOne: 'A maximum of one graphic',
        infoCanvas: `<span>Placement:</span> Front side of the canvas inside the dark grey frame,
              side
              Overlap up to the green line.`,
        infoPoster: `<span>Placement:</span> Placement of the
              Designs up to the outer green line. Gray
              Area is cut off during production.`,
        infoGlas: `<span>No placement:</span> For engraving work, we do not provide <b>any</b> placement for
                            Available, please change your vector graphics accordingly and then load the image
                            up again to adjust the placement.<br><br>
                      <span>Fixed aspect ratio:</span> Make sure that your SVG has the <b>width
                          {printMediumWidth} </b> and <b>Height {printMediumHeight}</b> hat.<br>
                            Alternatively, set the width and height so that they have the same aspect ratio as <b> {printMediumAspectRatio} </b>.<br><br>
                         <span>Only one design file:</span> There can be <b>only one</b> vector graphic for the design
                            be used.`,
        disabledStep2: `To get to the next step, you need to choose: Color, Size and Upload Image`,
        disabledStep3: `To proceed to the next step, you need to specify the product name, description and price`,
        uploadErrorMessageBeer:
            'SVG aspect ratio does not match the aspect ratio of the print medium (width/height = 1.67). Please try again with a matching file!',
        uploadErrorMessageWine:
            'SVG aspect ratio does not match the aspect ratio of the print medium (width/height = 1). Please try again with a matching file!',
        uploadErrorTitle: 'Upload Error',
        sleeveRight: 'Sleeve Right',
        sleeveLeft: 'Sleeve Left',
        frontLeft: 'Front Left',
        frontRight: 'Front Right',
        backLeft: 'Back Left',
        backRight: 'Back Right',
        sideLeft: 'Side Left',
        sideRight: 'Side Right',
        combinedDesignTitle: 'Same design on all sides',
        combinedDesignDescription: 'Disable this option if you want to have different designs on the left and right slipper',
        designDownload: 'Design Download',
        addToShop: 'Add to shop',
        sizeChartTooltip: 'The size chart is a table that shows your customer measurements of the product.',
    },
    publishProduct: {
        title: 'Add to Shop',
    },
    accountSettings: {
        title: 'Settings',
        shopSettings: 'Shop Settings',
        noShops: 'No shops added yet.',
        subtitle: 'Manage your profile settings.',
        contactInfo: 'Contact details',
        paymentInfo: 'Payment information',
        email: 'E-Mail',
        tel: 'Telephone',
        billingAddress: 'Billing',
        senderAddress: 'Sender address',
        paymentServiceProvider: 'Payment service',
        billingAgreement: 'Billing agreement',
        deposited: 'Deposited',
        changePaymentMethod: 'Change payment method',
        tryAgain: 'Try again',
        notifications: 'Notification',
        preferredLanguage: 'Preferred Language',
        preferredLanguageDescription:
            "When you're logged into MarketPrint, this is the language you see.<br/>You can change the language at any time.",
        productLanguage: 'Add Products to Your Shop',
        productLanguageDescription:
            'This changes the language of your product and the unit of measurement of the size chart when importing into your store.',
        apiToken: 'API Token',
        apiTokenDescription:
            'Here you can generate an API Token to use with different services. After generating your token, you can only see it once. Please save it in a safe place. You can always generate a new token, which will invalidate the old one.',
        generate: 'Generate',
        regenerate: 'Regenerate',
        popup: 'Please allow the pop-up window to store your payment data',
        notificationsCheckbox: {
            failedPayment: 'Receive notification when payments fail',
            personalizedDesign: 'Receive notification when a personalized design needs to be uploaded',
            tips: 'Would you like to receive tips and information from MarketPrint by E-Mail?',
        },
        editBillingAddress: 'Edit billing address',
        address: {
            title: 'Address',
            firstName: 'First name',
            lastName: 'Last name',
            address1: 'Address 1',
            address2: 'Address 2',
            company: 'Company',
            phone: 'Phone',
            email: 'E-Mail',
            name: 'Name / Company',
            address1Street: 'Street',
            address1Number: 'House Number',
            city: 'City',
            zip: 'Postcode',
            countryName: 'Country',
            validate: {
                zipWarning: {
                    title: 'Invalid Postcode!',
                    content: 'This ZIP code is not valid. Please check your input.',
                },
            },
            noChanges: 'Please make changes before saving',
            vatId: 'VAT ID',
            chooseExisting: '--- Choose existing address ---',
        },
        language: 'Language:',
        units: 'Units:',
        shoeSizes: 'Shoe Sizes:',
        editInvoiceAddressToasts: {
            success: {
                content: 'Your invoice address has been saved successfully.',
            },
            unavailable: {
                title: 'Warning',
                content: 'We could not verify your VAT ID at the moment, but your address was saved. Please try again later.',
            },
            invalid: {
                content: 'The given VAT ID is not registered.',
            },
            format: {
                content: 'The given VAT ID does not have the right format. Plase recheck your input.',
            },
            germanVat: {
                title: 'Error',
                content: 'A German VAT ID is not allowed.',
            },
        },
        save: 'Save',
        editSenderAddress: 'Edit sender address',
        general: 'Change address',
        selectPayment: 'Please choose',
        paypal: 'PayPal',
        creditCard: 'Credit card',
        notDeposited: 'Not deposited',
        deposit: 'Make a deposit',
        paymentError: 'Payment method not available',
        tib: 'TeeInBlue',
        connectTibProductInfo: 'Connect your TiB Product to MarketPrint',
        saveIntegrationApiInfosInfo: 'Save your TiB API information to beable to receive shipping updates in TeeInBlue',
        webhookUrl: 'Webhook URL',
        stockAvailabilityTitle: 'Stock Availability',
        stockAvailabilityCheckbox: 'Update stock availability automatically',
        stockAvailabilityDescription:
            'If this option is checked, your products are automatically set as sold out as soon as they are not available from our suppliers or the product has been discontinued by the manufacturer. <br> <br>If unchecked, then products will always be shown as available even if the product is discontinued or out of stock with our suppliers.',
        updateEmail: {
            title: 'Update Email',
            placeholder: 'New email',
            confirm: 'Confirm email',
            email: 'Email',
            button: 'Update Email',
            success: {
                title: 'Success',
                message: 'Please follow the instructions sent to your email to verify your new email address.',
            },
            error: {
                title: 'Update email error',
                emailExists: 'The given email is already registered.',
                rateLimit: 'Too many attempts. Please wait a moment and try again.',
                unknown: 'An unknown error occurred. Please try again later.',
            },
        },
        addShop: {
            title: 'Add shop',
            reconnectTitle: 'Shop has to be reconnected',
            howToLogin: 'Where do I find my URL?',
            add: 'Add',
        },
    },
    shopSelector: {
        noShops: 'No Shops Available',
        notConnected: '(uninstalled)',
        notOnboarded: '(not onboarded)',
        all: 'All Shops',
    },
    myProducts: {
        title: 'My products',
    },
    noResults: {
        text: 'There are no orders available yet',
        products: 'There are no products available in this category yet',
    },
    noShops: {
        connected: 'There are currently no shops connected. To connect a shop, go to the',
        settings: 'settings',
    },
    shared: {
        yes: 'Confirm',
    },
    invoices: {
        title: 'Invoices',
        startDate: 'Start date',
        endDate: 'End date',
        shop: 'Shop',
        download: 'PDF download',
        downloadCSV: 'CSV download',
        annotation: 'The download may take some time',
        noResults: 'There are no invoices for this time period.',
    },
    login: {
        install1: `Sign in<br>to `,
        install2: ' ',
        shopUrl: 'Enter a Shopify Domain (SHOPNAME.myshopify.com):',
        redirect: 'Redirected...',
        login: 'Login',
        marketprint: 'MarketPrint',
        company: 'MarketPrint - MarketConsultive GmbH',
        companyAddress: 'Allgäuerstraße 20, 87719 Mindelheim, Deutschland',
        password: 'Password',
        registerInstead: 'Register instead',
        error: {
            title: 'Login error',
            rateLimit: 'Too many login attempts. Please wait a moment and try again.',
            emailNotConfirmed: 'This email address has not yet been confirmed.',
            invalidCredentials: 'Invalid credentials.',
            firstTimeApiCallFailed: 'Your account was created but there was an internal error. Please try to log in or register again.',
            unknown: 'An unknown error occurred. Please try again later.',
        },
    },
    emailResend: {
        title: 'Resend Confirmation Email',
        success: {
            title: 'Success',
            message: 'The confirmation email has been resent.',
        },
        error: {
            title: 'Resend error',
            rateLimit: 'Too many attempts. Please wait a moment and try again.',
            unknown: 'An unknown error occurred. Please try again later.',
        },
    },
    forgotPassword: {
        forgotPassword: 'Forgot password?',
        enterEmail: 'Please enter your email address to receive a password reset link.',
        resetPassword: 'Reset password',
        success: {
            title: 'Success',
            message: 'An email has been sent to your email address with a password reset link.',
        },
        error: {
            title: 'Reset password error',
            unknown: 'An unknown error occurred. Please try again later.',
        },
    },
    updatePassword: {
        success: {
            title: 'Success',
            message: 'Your password has been updated successfully.',
        },
        error: {
            title: 'Update password error',
            samePassword: 'The password should differ from the old password.',
        },
        confirm: 'Password Confirmation',
        button: 'Update password',
        placeholder: 'New password',
        title: 'Update password',
        nonceMessage: 'A code has been sent to your email address. Please enter the code below to continue.',
        noncePlaceholder: 'One-time code',
        nonceInvalid: 'The provided code is invalid.',
    },
    register: {
        register: 'Register',
        loginInstead: 'Login instead',
        error: {
            title: 'Registration error',
            rateLimit: 'Too many registration attempts. Please wait a moment and try again.',
            emailExists: 'This email address is already registered.',
            weakPassword:
                'The password you entered is too weak. The password must contain at least 8 characters and contain a capital/lowercase letter, a number and a special character.',
            unknown: 'An unknown error occurred. Please try again later.',
        },
        success: {
            title: 'Registration successful',
            message: 'Your registration was successful. Please check your emails to confirm your account.',
        },
    },
    onboarding: {
        title: 'Welcome to MarketPrint!',
        welcomeText: `Thank you for using our Print on Demand service!<br><br>
            We are a modern & passionate company that offers an all-in-one print on demand service and does everything to offer Shopify online merchants the best and easiest user experience in online commerce.<br>
            We are continuously working on our app and our service to make the Print on Demand business model accessible to everyone and to offer you the opportunity to access an ever-growing product portfolio and an automated ordering process.<br><br>
            We are always open to suggestions for improvements regarding our Shopify app. Feel free to call us for this or write to our support.<br><br>
            Have fun with the MarketPrint app and have lots of good sales,<br>
            Team MarketPrint`,
        privacy:
            "I have read the <a href='https://marketprint.de/agb/' target='blank'>Terms and Conditions</a> and <a href='https://marketprint.de/datenschutzerklarung-marketprint-app/' target='_blank'>Privacy Policy</a> and agree to them",
        emailNotifications: 'I would like to be informed by MarketPrint about new products and news by e-mail',
        formTitle: 'Very important for the perfect start:',
        formText: `In order for your incoming orders to be processed automatically, you must enter your payment method. We offer PayPal or credit card as an option and recommend that you deposit your payment method & billing address now for a smooth start.<br><br>
            Please fill out the form and choose the payment method that is right for you:`,
        billingAddress: 'Add billing address:',
        name: 'Name:',
        namePlaceholder: 'Enter your name',
        firstName: 'First name:',
        firstNamePlaceholder: 'Enter first name',
        company: 'Name / Company:',
        companyPlaceholder: 'Specify your name or company name',
        addressStreet: 'Street:',
        addressStreetPlaceholder: 'Add street',
        addressNumber: 'House number:',
        addressNumberPlaceholder: 'Add house number',
        postcode: 'ZIP:',
        postcodePlaceholder: 'Add ZIP',
        country: 'Country:',
        countryPlaceholder: 'Select a country',
        paymentMethod: 'Select a payment service provider:',
        thankYouTitle: 'You did it! 🎉',
        thankYouText: `You have done everything and are ready to create and launch your own brand.<br>
            Let's create your very first product together. You will see how easy and fast this is.<br><br>
            Try it out right now, just click on the button and let's create your first product directly with one of our bestsellers.`,
        thankYouNext: 'Get started now!',
        thankYouBack: 'Skip',
        city: 'City:',
        cityPlaceholder: 'Add city',
        error: {
            title: 'Account information could not be stored',
            backendContent: 'Errors when storing the data',
        },
    },
    success: {
        title: 'Success',
    },
    error: {
        title: 'Error',
        content: 'An error occurred',
    },
    header: {
        notifications: 'Notifications',
    },
    countries: {
        Deutschland: 'Germany',
        Österreich: 'Austria',
        Schweiz: 'Switzerland',
        Andorra: 'Andorra',
        Belgien: 'Belgium',
        Bulgarien: 'Bulgaria',
        Dänemark: 'Denmark',
        Estland: 'Estonia',
        Finnland: 'Finland',
        Frankreich: 'France',
        Griechenland: 'Greece',
        Irland: 'Ireland',
        Italien: 'Italy',
        Kroatien: 'Croatia',
        Lettland: 'Latvia',
        Luxemburg: 'Luxembourg',
        Monaco: 'Monaco',
        Niederlande: 'Netherlands',
        Norwegen: 'Norway',
        Polen: 'Poland',
        Portugal: 'Portugal',
        'San Marino': 'San Marino',
        Schweden: 'Sweden',
        Slowakei: 'Slovakia',
        Slowenien: 'Slovenia',
        Spanien: 'Spain',
        'Tschechische Republik': 'Czech Republic',
        Ungarn: 'Hungary',
        Vatikanstadt: 'Vatican City',
        'Vereinigtes Königreich': 'United Kingdom',
        Zypern: 'Cyprus',
        Kosovo: 'Kosovo',
        Litauen: 'Lithuania',
        Malta: 'Malta',
        Rumänien: 'Romania',
        Albanien: 'Albania',
        'Bosnien und Herzegowina': 'Bosnia and Herzegovina',
        Liechtenstein: 'Liechtenstein',
        Mazedonien: 'Macedonia',
        Moldau: 'Moldova',
        Montenegro: 'Montenegro',
        Serbien: 'Serbia',
        'Vereinigte Staaten': 'United States',
        'Zone 1': 'Zone 1',
        'Zone 2': 'Zone 2',
        'Zone 3': 'Zone 3',
    },
    codeToCountry: {
        AE: 'United Arab Emirates',
        AT: 'Austria',
        BE: 'Belgium',
        BG: 'Bulgaria',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DE: 'Germany',
        DK: 'Denmark',
        EE: 'Estonia',
        EL: 'Greece',
        ES: 'Spain',
        FI: 'Finland',
        FR: 'France',
        GR: 'Greece',
        HR: 'Croatia',
        HU: 'Hungary',
        IE: 'Ireland',
        IT: 'Italy',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        LV: 'Latvia',
        MT: 'Malta',
        NL: 'Netherlands',
        PL: 'Poland',
        PT: 'Portugal',
        RO: 'Romania',
        SE: 'Sweden',
        SI: 'Slovenia',
        SK: 'Slovakia',
        GB: 'United Kingdom',
        CH: 'Switzerland',
        NO: 'Norway',
        US: 'United States',
    },
    shippingTable: {
        canvas: 'Canvas',
        poster: 'Poster',
        basePrice: 'Base Price',
        perAdditionalProduct: 'Per Additional Product',
    },
    time: {
        today: 'Today',
        nDaysAgo: '{n} days ago',
        '1DayAgo': '1 day ago',
    },
    unitSystems: {
        metric: 'Metric',
        imperial: 'Imperial',
    },
    shoeSizeSystem: {
        eu: 'EU',
        us: 'US',
        uk: 'UK',
    },

    affiliateProgram: {
        title: 'Affiliate Program',
        activeShopsLastYear: 'Stores younger than 1 year',
        allShops: 'All Shops',
        payouts: 'Payouts',
        payoutDate: 'Payout Date',
        payoutAmount: 'Payout Amount',
        payoutStatus: 'Payout Status',
        btnDownloadPayout: 'Download Payout',
        payoutInfo: 'Payout Information',
        paymentServiceProvider: 'Payment service',
        billingAgreement: 'Billing agreement',
        deposited: 'Deposited',
        addNewPaymentMethod: 'Add new payment method',
        changePaymentMethod: 'Update payment method',
        tryAgain: 'Try again',
        popup: 'Please allow the pop-up window to store your payment data',
        selectPayment: 'Please choose',
        paypal: 'PayPal',
        creditCard: 'Credit card',
        billingAddress: 'Billing address',
        paymentStatus: {
            open: 'Open',
            invoice_upload_pending: 'Invoice Upload Pending',
            invoice_being_verified: 'Invoice Being Verified',
            payment_pending: 'Payment Pending',
            paid: 'Paid',
            failed: 'Failed',
        },
    },
};

export default app;
